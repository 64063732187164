* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "light";
  src: url('./assets/fonts/Nunito-Light.ttf') format('truetype');
}

@font-face {
  font-family: "regular";
  src: url('./assets/fonts/Nunito-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "bold";
  src: url('./assets/fonts/Nunito-Bold.ttf') format('truetype');
}

@font-face {
  font-family: "black";
  src: url('./assets/fonts/Nunito-Black.ttf') format('truetype');
}

@font-face {
  font-family: "italic";
  src: url('./assets/fonts/Nunito-Italic.ttf') format('truetype');
}

@font-face {
  font-family: "lightItalic";
  src: url('./assets/fonts/Nunito-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: "dalek";
  src: url('./assets/fonts/Dalek.ttf') format('truetype');
}
